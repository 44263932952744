









































































































































































































































































































































































































































































import { Action, Getter } from "vuex-class";
import { Component, Ref, Vue, Watch } from "vue-property-decorator";
import {
  CategoryResponse,
  ShopCategory,
  ShopDisclaimer,
  ShopItem,
  ShopItemResponse,
} from "@/store/generalSettings/types";

import { Currency, VForm } from "@/types";
import { FormRule, isValidUrl, notEmpty } from "@/helpers/form.validators";
import { RouteName } from "@/router/types";
import { CoreConfigGetters, TenantConfig } from "@/spect8-core-vue/src/types";

@Component
export default class SettingsShop extends Vue {
  @Action("generalSettings/createShopDisclaimer")
  createShopDisclaimer!: (data: ShopDisclaimer) => void;
  @Action("generalSettings/getDisclaimers")
  getDisclaimers!: () => Promise<ShopDisclaimer[]>;
  @Action("generalSettings/deleteDisclaimer")
  deleteDisclaimer!: (id: string) => void;
  @Action("generalSettings/updateDisclaimer")
  updateDisclaimer!: (disclaimer: ShopDisclaimer) => void;
  @Action("generalSettings/createCategory")
  createCategory!: (data: ShopCategory) => void;
  @Action("generalSettings/getCategories")
  getCategories!: () => Promise<CategoryResponse[]>;
  @Action("generalSettings/deleteCategory")
  deleteCategory!: (id: string) => Promise<void>;
  @Action("generalSettings/createShopItem")
  createShopItemAction!: (data: ShopItem) => Promise<void>;
  @Action("generalSettings/getShopItems")
  getShopItems!: (categoryId: string) => Promise<ShopItemResponse[]>;
  @Action("generalSettings/deleteShopItem")
  deleteShopItem!: (id: string) => void;

  @Action("generalSettings/updateShopItem")
  updateShopItem!: (data: ShopItem) => Promise<void>;

  @Getter("generalSettings/getShopCategories")
  readonly shopCategories!: CategoryResponse[];
  @Getter("generalSettings/getShopItems")
  readonly shopItems!: ShopItem[];
  @Getter("generalSettings/getDisclaimers")
  readonly disclaimers!: ShopDisclaimer[];

  @Getter(CoreConfigGetters.TenantConfig)
  readonly tenantConfig!: TenantConfig;

  @Ref("addItemForm") addItemForm!: VForm;

  RouteName = RouteName;

  categoryName = "";
  loading = true;
  dialog = false;
  addCategory = false;
  previewDialog = false;

  currencies = Object.values(Currency);

  clickedCategory: CategoryResponse | null = null;

  confirmDialog = false;
  deleteItemBool = false;
  deleteCategoryBool = false;
  closeShopBool = false;

  catId = "";
  itemId = "";
  disclaimer = "";
  disclaimerLink = "";
  updateDis = false;
  addDis = false;
  deleteDis = false;

  saveInProgress = false;
  formErrors = {};

  nameRules: FormRule[] = [notEmpty];
  linkRules: FormRule[] = [notEmpty, isValidUrl];

  shopItem: ShopItem = {
    categoryId: "",
    itemName: "",
    itemDetails: "",
    url: "",
    itemPrice: 0,
    currency: "",
    redirectLink: "",
  };

  addItemFormValid = true;

  itemPriceValidation = {
    // Output edge case error messages.
    onKeyDown: (e: KeyboardEvent /*, vm: SettingsShop */) => {
      switch (e.key) {
        case ",":
          this.$set(
            this.formErrors,
            "itemPrice",
            "Please use . as a separator"
          );
          break;
        case ".":
          this.$delete(this.formErrors, "itemPrice");
          break;
        default:
          this.$delete(this.formErrors, "itemPrice");
      }
    },
    // Prevent price from exceeding 2 decimal places and strip leading 0's
    onKeyUp: (e: KeyboardEvent, vm: SettingsShop) => {
      const itemPrice = vm.shopItem.itemPrice.toString();
      const pricePieces = itemPrice.toString().split(".");

      if (pricePieces.length > 1 && pricePieces[1].length > 2) {
        vm.shopItem.itemPrice = parseFloat(
          itemPrice.substr(0, itemPrice.length - 1)
        );
      }

      if (itemPrice !== "0" && itemPrice[0] === "0") {
        vm.shopItem.itemPrice = parseFloat(itemPrice.replace(/^0+/, ""));
      }
    },
    // Verify price matches pattern and formats the value to two decimal places.
    validate: (e: Event, vm: SettingsShop) => {
      const pattern = /^\d+(\.?\d{1,2})?$/;
      const itemPrice = vm.shopItem.itemPrice.toString();

      if (e.target) {
        const target = e.target as HTMLInputElement;
        target.value = parseFloat(target.value).toFixed(2);
      }

      if (!pattern.test(itemPrice)) {
        this.$set(this.formErrors, "itemPrice", "Please enter a valid price.");
      } else {
        this.$delete(this.formErrors, "itemPrice");
      }
    },
  };

  confirmDialogAction(): void {
    const { catId, itemId, deleteCategoryBool, deleteItemBool, closeShopBool } =
      this;
    const { deleteCategory, deleteItem, closeShopModal } = this;

    switch (true) {
      case deleteCategoryBool:
        deleteCategory(catId).then(() => (this.confirmDialog = false));
        break;
      case deleteItemBool:
        deleteItem(itemId);
        break;
      case closeShopBool:
        closeShopModal();
        break;
    }
  }

  maybeCloseShopModal() {
    let originalItem;
    const { shopItem, shopItems } = this;

    if (shopItem.id) {
      originalItem = shopItems.find((item) => item.id === shopItem.id);
    }

    if (!originalItem) {
      originalItem = this.getInitialData();
    }

    if (originalItem && shopItem) {
      if (JSON.stringify(originalItem) !== JSON.stringify(shopItem)) {
        this.confirmDialog = true;
        this.closeShopBool = true;
        this.deleteItemBool = false;
        this.deleteCategoryBool = false;
        return;
      }
    }

    this.closeShopModal();
  }

  closeShopModal() {
    this.confirmDialog = false;
    this.dialog = false;
    this.addItemForm.resetValidation();
  }

  async saveShopItem() {
    const { shopItem } = this;

    if (!this.addItemForm.validate()) {
      return;
    }

    this.saveInProgress = true;

    try {
      if (shopItem.id) {
        await this.updateShopItem(shopItem);
      } else {
        await this.createShopItemAction({
          ...shopItem,
          url: "https://media.istockphoto.com/vectors/no-image-available-sign-vector-id922962354?k=6&m=922962354&s=612x612&w=0&h=_KKNzEwxMkutv-DtQ4f54yA5nc39Ojb_KPvoV__aHyU=",
        });
      }

      this.dialog = false;
      this.shopItem = this.getInitialData();
      this.addItemForm.resetValidation();
    } finally {
      this.saveInProgress = false;
    }
  }

  editItem(item: ShopItemResponse) {
    const shopItem: ShopItem = {
      ...item,
      // Temp solution: This is a problem with the current API returning category id as an array of ids.
      categoryId: item.categoryId.length
        ? item.categoryId[0]
        : this.shopCategories[0].id,
    };

    this.shopItem = shopItem;
    this.dialog = true;
    this.addItemForm.resetValidation();
  }

  createDisclaimer() {
    if (this.addDis) {
      this.createShopDisclaimer({
        disclaimer: this.disclaimer,
        link: this.disclaimerLink,
      });
      this.disclaimerLink = "";
    }
    if (this.updateDis) {
      this.updateDisclaimer({
        id: this.disclaimers[0].id,
        disclaimer: this.disclaimer,
        link: this.disclaimerLink,
      });
    }
    if (this.deleteDis) {
      if (this.disclaimers[0].id) this.deleteDisclaimer(this.disclaimers[0].id);
      this.disclaimer = "";
      this.disclaimerLink = "";
    }
  }

  @Watch("disclaimer")
  onDisclaimerChanged(newDisclaimer: string) {
    if (newDisclaimer) {
      const regex = /(https?:\/\/[^\s]+)/g;
      const linkList = this.disclaimer.match(regex);
      if (linkList) this.disclaimerLink = linkList[0];
    }
    if (newDisclaimer.length === 0 && this.disclaimers.length === 1) {
      this.deleteDis = true;
      this.updateDis = false;
      this.addDis = false;
    } else if (
      this.disclaimers[0] &&
      newDisclaimer !== this.disclaimers[0].disclaimer
    ) {
      this.deleteDis = false;
      this.updateDis = true;
      this.addDis = false;
    } else {
      this.deleteDis = false;
      this.updateDis = false;
      this.addDis = true;
    }
  }

  filterCategory(category: CategoryResponse) {
    this.clickedCategory = category;
    this.getShopItems(category.id);
  }

  prepareDelete(category: CategoryResponse) {
    this.confirmDialog = true;
    this.catId = category.id;
    this.deleteCategoryBool = true;
    this.deleteItemBool = false;
  }

  prepareItemDelete(item: ShopItem) {
    this.confirmDialog = true;
    if (item.id) this.itemId = item.id;
    this.deleteItemBool = true;
    this.deleteCategoryBool = false;
  }

  deleteItem(id: string) {
    this.deleteShopItem(id);
    this.confirmDialog = false;
  }

  createShopItem() {
    this.shopItem = this.getInitialData();
    this.dialog = true;
    this.addItemForm.resetValidation();
  }

  uploadImage() {
    (this.$refs.fileInput as HTMLInputElement).click();
  }

  onFileSelected(event: Event) {
    const target = event.target as HTMLInputElement;
    const file =
      target?.files !== null && target?.files.length > 0
        ? target?.files[0]
        : null;

    if (file) {
      this.$emit("selectedfile", file);
      this.shopItem.url = URL.createObjectURL(file);
      this.shopItem.image = file;
    }
  }

  redirect(link: string) {
    if (!link.match(/^https?:\/\//i)) {
      link = "http://" + link;
    }
    return window.open(link);
  }

  addNewCategory() {
    if (this.tenantConfig.tenantId) {
      this.createCategory({
        categoryName: this.categoryName,
      });
      this.categoryName = "";
    }
  }

  getInitialData(): ShopItem {
    const shopItem: ShopItem = {
      id: undefined,
      categoryId: "",
      itemName: "",
      itemDetails: "",
      url: "",
      image: null,
      itemPrice: 0,
      currency: "",
      redirectLink: "",
    };

    return shopItem;
  }

  getClickedCategory(category: CategoryResponse) {
    return this.clickedCategory?.id === category.id || false;
  }

  getCurrency(cur: string) {
    if (cur === "€ (EUR)") return "€";
    else if (cur === "$ (USD)") return "$";
    else return "";
  }

  get categories() {
    const list = this.shopCategories.filter((obj) => {
      return obj.categoryName !== "All";
    });
    return list;
  }

  get disabledDisclaimer() {
    if (
      this.disclaimers[0] &&
      this.disclaimer === this.disclaimers[0].disclaimer
    )
      return true;
    else return false;
  }

  get disabledAddItemButton() {
    if (this.shopCategories[0]) return false;
    else return true;
  }

  get disabledAddCategoryButton() {
    if (this.categoryName || this.categoryName.length > 0) return false;
    return true;
  }

  get isEditing(): boolean {
    return this.shopItem.id ? true : false;
  }

  get disableSaveItemButton() {
    if (this.saveInProgress) {
      return true;
    }

    return !this.addItemFormValid;
  }

  mounted() {
    this.getCategories().then((categories: CategoryResponse[]) => {
      if (categories[0])
        this.getShopItems(categories[0].id).then(() => {
          this.getDisclaimers().then((disclaimers: ShopDisclaimer[]) => {
            if (disclaimers[0]) this.disclaimer = disclaimers[0].disclaimer;
            this.clickedCategory = this.shopCategories[0];
            this.loading = false;
          });
        });
      else this.loading = false;
    });
  }
}
