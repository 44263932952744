// export type CsvMap = { [index: string]: string };

export enum Currency {
  EUR = "€ (EUR)",
  USD = "$ (USD)",
}

export type VForm = Vue & {
  validate(): boolean;
  reset(): void;
  resetValidation(): void;
};

export type MimeType = {
  fileType: string;
  extensions: string[];
};

export interface Permission {
  scope: PermissionScope;
  entityId: string;
}

export enum PermissionScope {
  USER = "USER",
  GROUP = "GROUP",
  ROLE = "ROLE",
}

export interface MessageRouter {
  tenantWide: boolean;
  tenantId?: string;
  broadcasts: BroadcastPayload[];
  channels: ChannelPayload[];
}

export interface BroadcastPayload {
  broadcastId: string;
  broadcastName: string;
  tenantId: string;
}

export interface ChannelPayload {
  broadcastId: string;
  channelId: string;
  channelName: string;
}

export interface MessageRouterPayload {
  tenantWide: boolean;
  broadcastIds: string[];
  channelIds: string[];
}

export interface VTableHeaders {
  text: string;
  value: string;
  align?: "start" | "center" | "end";
  sortable?: boolean;
  filterable?: boolean;
  groupable?: boolean;
  divider?: boolean;
  class?: string | string[];
  cellClass?: string | string[];
  width?: string | number;
  filter?: (value: any, search: string, item: any) => boolean;
  sort?: (a: any, b: any) => number;
}

export type VueHtml2Pdf = {
  generatePdf(): void;
};
