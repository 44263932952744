












import SettingsShop from "@/components/General/SettingsShop.vue";
import PageTemplate from "@/components/PageTemplate.vue";
import { RouteName } from "@/router/types";
import { Vue, Component } from "vue-property-decorator";

@Component({
  components: {
    PageTemplate,
    SettingsShop,
  },
})
export default class ShopView extends Vue {
  RouteName = RouteName;
}
